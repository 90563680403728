// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfmeta from "../../blocks/cfmeta/src/Cfmeta";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cftiktokintegration3 from "../../blocks/cftiktokintegration3/src/Cftiktokintegration3";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Cfwallet49 from "../../blocks/cfwallet49/src/Cfwallet49";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfwhatsappintegration303 from "../../blocks/cfwhatsappintegration303/src/Cfwhatsappintegration303";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PasscodeLock from "../../blocks/passcodelock/src/PasscodeLock";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";


const routeMap = {
Cfmeta:{
 component:Cfmeta,
path:"/Cfmeta"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cftiktokintegration3:{
 component:Cftiktokintegration3,
path:"/Cftiktokintegration3"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Cfwallet49:{
 component:Cfwallet49,
path:"/Cfwallet49"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfwhatsappintegration303:{
 component:Cfwhatsappintegration303,
path:"/Cfwhatsappintegration303"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
PasscodeLock:{
 component:PasscodeLock,
path:"/PasscodeLock"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;